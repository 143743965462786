import React from 'react';
import axios from 'axios';
import config from '../config';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }
    componentDidCatch(error, errorInfo) {
        axios.post(config.apiUrl + 'logs', {
            the_error: error.toString(),
            where_it_occurred: errorInfo.componentStack,
            logged_in_userid: (JSON.parse(localStorage.getItem('user'))).userid,
            url: window.location.href
        }).then((response) => {

        }).catch((error) => {
        })
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                    <div className="sec-pd process-sec">
                        <div className="text-center"><i className={"fa fa-warning big-icon danger"}></i></div>
                        <h3 className="text-center mb-2 head-xl black">Something went wrong!</h3>
                        <p className="text-center f-med mb-3">Oops! Something went wrong while processing your request. We have notified this to the development team and they will soon fix it.</p>
                    </div>
                    );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;