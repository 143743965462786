 import React, { useEffect } from 'react';
import { Collapse , Spinner } from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Row, Col, Form, Dropdown } from 'react-bootstrap';
import SearchResult from './SearchResult';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import config from '../../config';
import customHistory from '../../helpers/custom-history';
import { loadingStateChange } from '../../Redux/Actions/GlobalActions';
import { connect } from 'react-redux';
import _ from 'underscore';

const SearchForm = (props) => {
   const initialValues = {
      dotnumber: localStorage.getItem('dotnumber') === null ? '' : localStorage.getItem('dotnumber'),
      phone_number: localStorage.getItem('phone_number') === null ? '' : localStorage.getItem('phone_number'),
      company_name: localStorage.getItem('company_name') === null ? '' : localStorage.getItem('company_name'),
      email: localStorage.getItem('email') === null ? '' : localStorage.getItem('email')
   }

   const clearValues = () => {
      localStorage.setItem('dotnumber', '');
      localStorage.setItem('phone_number', '');
      localStorage.setItem('email', '');
      localStorage.setItem('company_name', '');
      formik.values.dotnumber = '';
      formik.values.phone_number = '';
      formik.values.email = '';
      formik.values.company_name = '';
      props.refreshForm();
   }

	const formik = useFormik({
     initialValues: initialValues,
     validationSchema: Yup.object({
        dotnumber:Yup.string()
        .matches(
			 	/^\d{1,7}?$/,
	        {
	            message: 'Please enter a valid DOT Number',
	            excludeEmptyString: true
	        },
	    ),
        email: Yup.string()
       .matches(
			 	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
	        {
	            message: 'Please enter a valid Email Address',
	            excludeEmptyString: true
	        },
	    ),
        company_name: Yup.string()
       .matches(
			 	/[\w-"\' ]{5}/i,
	        {
	            message: 'Please enter at least 5 characters to search by Company Name',
	            excludeEmptyString: true
	        },
	    ),
        phone_number: Yup.string()
       .matches(
			 	/^(\+\d{1,2}[\s])?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
	        {
	            message: 'Please enter a valid Phone Number',
	            excludeEmptyString: true
	        },
	    ),
       atleast_one_field: Yup.string().test(
          'Unique Email','Please enter atleast one field',
          function(value){
              if(!this.parent.dotnumber && !this.parent.phone_number
                && !this.parent.email && !this.parent.company_name) {
                return false;
              } else {
                return true;
              }
          }
        )
     }),
     onSubmit: values => {
      localStorage.setItem('dotnumber', values.dotnumber);
      localStorage.setItem('phone_number', values.phone_number);
      localStorage.setItem('email', values.email);
      localStorage.setItem('company_name', values.company_name);
     	props.searchSubmit(values);
     }
   });

   const items = []

	props.searchResults.map(result => (
 		items.push(<SearchResult customHistory={props.customHistory} result ={result} />)
   ))

  useEffect(() => {
      if((formik.values.dotnumber || formik.values.email || formik.values.phone_number || formik.values.company_name) && props.formError) {
          props.refreshForm();
      }
  }, [formik.values]);

   return (
   		<React.Fragment>
		   <Collapse in={props.showSearchBar}>
			<div className="" id="common_search">
				<div className="common-search">
					<div className="inner-container">
                  <Form style={{width: "100%", display: "inherit"}} onSubmit={formik.handleSubmit}>
                  <Row>

							<Col sm={6} lg={3}>
							<Form.Group controlId="">
                <div className="search-input-outer">
									<Form.Control
										type="number"
										className="border-input"
										placeholder="Search by DOT Number"
										id='dot_number'
										name='dotnumber'
										onChange={formik.handleChange}
                              value={formik.values.dotnumber}
									/>
                  </div>
                           {formik.touched.dotnumber && formik.errors.dotnumber ? (
        					        <label className="error">{formik.errors.dotnumber}</label>
        					    ) : null}
      						</Form.Group>
							</Col>

							<Col sm={6} lg={3}>
							<Form.Group controlId="">
                <div className="search-input-outer">
									<Form.Control
										type="text"
										className="border-input"
										placeholder="Search by Email Address"
										id='email'
										name='email'
										onChange={formik.handleChange}
                              value={formik.values.email}
									/>
                  </div>
                           {formik.touched.email && formik.errors.email ? (
                             <label className="error">{formik.errors.email}</label>
                         ) : null}
							</Form.Group>
							</Col>

							<Col sm={6} lg={3}>
							<Form.Group controlId="">
                <div className="search-input-outer">

									<Form.Control
										type="text"
										className="border-input"
										placeholder="Search by Phone Number"
										id='phone_number'
										name='phone_number'
										onChange={formik.handleChange}
                              value={formik.values.phone_number}
									/>
                  </div>
                           {formik.touched.phone_number && formik.errors.phone_number ? (
                             <label className="error">{formik.errors.phone_number}</label>
                         ) : null}
							</Form.Group>
							</Col>

							<Col sm={6} lg={3}>
							<Form.Group controlId="">
                <div className="search-input-outer">
									<Form.Control
										type="text"
										className="border-input"
										placeholder="Search by Company Name"
										id='company_name'
										name='company_name'
										onChange={formik.handleChange}
                              value={formik.values.company_name}
									/>
                  </div>
                           {formik.touched.company_name && formik.errors.company_name ? (
                             <label className="error">{formik.errors.company_name}</label>
                         ) : null}
                          {
                            props.formError ?
                              <label className="error">{props.formError}</label> :
                              (formik.errors.atleast_one_field ? (<label className="error">{formik.errors.atleast_one_field}</label>)
                                : null )
                          }
              </Form.Group>

							</Col>

						</Row>
						{ props.searchFieldsError != "" && <span class="error">{props.searchFieldsError}</span> }

						<div className="text-right">
                     <button type="submit" className="theme-btn alter sm" id="submit-search" title="Search">SEARCH
                        {props.loading && <Spinner size="sm" animation="border" role="status">
   							  <span className="sr-only">Loading...</span>
   							</Spinner>}
                     </button>
                     <button onClick={clearValues} type="button" className="theme-btn ml-1 grey sm" title="Reset">RESET</button>

						</div>
               </Form>
					</div>
				</div>
				<div className="search-results-container">
          <div className="search-results common-pd">
            <div className="inner-container">
    					{ /* search results div */}
              { props.searchResults.length != 0 && items }
    					{props.loading && props.currentPage > 1 && <p className="text-center"><Spinner size="sm" animation="border" role="status">
	                   			<span className="sr-only">Loading...</span>
	                  		</Spinner></p>}
           </div>
			    </div>
				</div>

				{ /*search results div */}
			</div>
			{ /*common search*/}
			</Collapse>
		</React.Fragment>
	)
}

class Search extends React.Component {
	constructor(props) {
		super(props);
   }

   handleScroll = (event) => {
	    if(this.props.showSearchBar) {
  			_.debounce(this.setScrollTop(event.target.scrollingElement.scrollTop), 1000);
  		}

   		if(
   			(event.target.scrollingElement.scrollHeight - event.target.scrollingElement.scrollTop) - window.innerHeight < 350
   			&& !this.state.loadMoreStrated
   			&& (this.state.currentPage * this.state.perPage) < this.state.totalRecords
   		) {
   			//Load more values
   			this.setState((prevState, prevProps) => {
   				return {
   					currentPage: prevState.currentPage + 1,
   					loadMoreStrated: true
   				}
   			}, () => {
   				this.searchSubmit({
   					...this.state.searchParams,
   					page: this.state.currentPage
   				}, false);
   			});
   		}
   }

	 setScrollTop = (scrollTop) => {
		  this.setState({ ...this.state, scrollTop: scrollTop });
   }

   refreshForm = () => {
      this.setState({ formRefreshed: true, formError: false });
   }

   state = {
      loading: false,
      searchResults: [],
      searchFieldsError: "",
      errorMessage: {
         phone_number: "",
         dotnumber: "",
         company_name: "",
         email: ""
      },
      currentPage: 1,
      totalRecords: 0,
      perPage: 25,
      searchStarted: false,
	  loadMoreStrated: false,
	  scrollTop: 0
   }

   setCurrentPage = (e, formik) =>
   {
      e.preventDefault()
      this.setState({
         loadMoreStrated: true
      })
      setTimeout(() => {
         formik.handleSubmit()
      },100)
   }

   searchSubmit = (searchParams, resetPageNumber = true) => {
      this.setState({ loading: true });


		let uriString = []
      	if(!resetPageNumber) {
			searchParams['page'] = this.state.currentPage;
      	} else {
      		searchParams['page'] = 1;
      	}
		for (const [key, value] of Object.entries(searchParams)) {
			uriString.push(key+"="+value)
		}


    this.props.loadingStateChange(true);


		setTimeout(() => {
			axios.get(config.apiUrl+'search?'+uriString.join("&") )
			.then((response) => {
          this.props.loadingStateChange(false);
            if(response.data.data.total == 0) {
              this.setState({
                formError: 'No results found'
              });
            }
				this.setState({
			   searchParams: searchParams,
               loading: false,
					searchResults: this.state.loadMoreStrated?this.state.searchResults.concat(response.data.data.data):response.data.data.data,
					searchFieldsError:"",
               errorMessage: {
                  phone_number: "",
                  dotnumber: "",
                  company_name: "",
                  email: ""
               },
					currentPage: response.data.data.current_page,
					totalRecords: response.data.data.total,
					perPage: response.data.data.per_page,
               searchStarted: true,
               loadMoreStrated: false
				})
			})
			.catch(error => {
        this.props.loadingStateChange(false);
        this.setState({ ...this.state, loading: false });
				if (error.response.status == 400){
					this.setState({
            	loading: false,
             loadMoreStrated: false
      		});
          this.setState({
	  				formError: error.response.data.status.message
	  			});
				}else if (error.response.status == 422) {
					const errorMessage = error.response.data.errors
					this.setState({
                  		loading: false,
						searchResults: [],
						searchFieldsError: "",
		                errorMessage: {
		                	phone_number: errorMessage.phone_number && errorMessage.phone_number[0],
							dotnumber:  errorMessage.dotnumber && errorMessage.dotnumber[0],
							company_name:  errorMessage.company_name && errorMessage.company_name[0],
							email:  errorMessage.email && errorMessage.email[0]
		                },
						currentPage: 0,
						totalRecords: 0,
						perPage: 25,
                  searchStarted: true,
                  loadMoreStrated: false
					})
				}
			})
		}, 100)

	}

	componentDidUpdate = (prevProp, prevState) => {
		if(prevProp.showSearchBar != this.props.showSearchBar && this.props.showSearchBar) {
			setTimeout(() => {
				window.scroll({
          left: 0,
          top: this.state.scrollTop,
          transition: 'smooth'
        });
        window.addEventListener('scroll', this.handleScroll);
			}, 500);
		} else if(prevProp.showSearchBar != this.props.showSearchBar && !this.props.showSearchBar) {
      window.removeEventListener('scroll', this.handleScroll);
    }

    if(prevProp.user != this.props.user && !this.props.user) {
        this.setState({
          loading: false,
          searchResults: [],
          searchFieldsError: "",
          errorMessage: {
             phone_number: "",
             dotnumber: "",
             company_name: "",
             email: ""
          },
          currentPage: 1,
          totalRecords: 0,
          perPage: 25,
          searchStarted: false,
          loadMoreStrated: false,
          scrollTop: 0
        })
    }
	}

   render() {
		return (
			<React.Fragment>
				  {this.props.user && <SearchForm scrollTop={this.state.scrollTop} showSearchBar={this.props.showSearchBar} customHistory={this.props.customHistory} formError={this.state.formError} refreshForm={this.refreshForm} currentPage={this.state.currentPage} showSearch={this.props.showSearch} totalPages={Math.ceil(this.state.totalRecords/this.state.perPage)} searchSubmit={this.searchSubmit} searchResults={this.state.searchResults} searchFieldsError = {this.state.searchFieldsError} currentPage = {this.state.currentPage} setCurrentPage = {this.setCurrentPage} errorMessage={this.errorMessage} loading={this.state.loading} searchStarted={this.state.searchStarted}/>}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
    user: state.global.user
})

export default connect(mapStateToProps, {loadingStateChange})(Search);
