import axios from 'axios';
import store from '../Redux/Store';
import { USER_LOGGED_OUT, GLOBAL_MESSAGE_TOGGLE } from '../Redux/Actions/ActionTypes';
import customHistory from './custom-history';

//Set common config
axios.interceptors.request.use(function (config) {
	if(store.getState().global.token) {
		const token = store.getState().global.token.replace(/['"]+/g, '');
	    config.headers.Authorization =  `Bearer ${token}`;
	}

    return config;
});

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status.code) {
    	store.dispatch({
	        type: USER_LOGGED_OUT,
	        payload: null
	    });
	    store.dispatch({
	    	type: GLOBAL_MESSAGE_TOGGLE,
	    	payload: {
	    		'message': error.response && error.response.data ? error.response.data.message : 'You have been logged out. As you have logged in from a different location!',
	    		'type': 'danger'
	    	}
	    });
	    localStorage.clear();
	    customHistory.push('/');
	    return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

export default axios;