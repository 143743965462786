import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { Router, Switch, Route, useHistory } from "react-router-dom";
import { Alert, Button } from 'react-bootstrap';
import Search from './Components/UI/Search';

import axios from './helpers/axios';
import customHistory from './helpers/custom-history';
import config from './config';
import commonHelper from './helpers/common';

import store from './Redux/Store';
import {
    USER_CLICKED_APP,
    USER_INCREASE_IDLE_TIME,
    USER_LAST_LOGIN_TIME_UPDATED,
    GLOBAL_MESSAGE_TOGGLE, USER_LOGGED_OUT
} from './Redux/Actions/ActionTypes';
import styles from './main.scss';
import logo from './logo.svg';
import ErrorBoundary from './Components/ErrorBoundary';
import Header from './Components/Navigation/Header';
import Spinner from './Components/UI/Spinner';
import Messages from './Components/UI/Messages';
import IdleTimeModal from './Components/UI/IdleTimeLogoutModal';
import PrivateRoute from './Components/Pages/PrivateRoute';
import ContainerLoader from './Components/UI/ContainerLoader';
import LogoutConfirmBox from './Components/UI/LogoutConfirmationModal';

const Login = React.lazy(() => import('./Components/Pages/Login/Login'));
const Dashboard = React.lazy(() => import('./Components/Pages/Dashboard/Dashboard'));
const SaleProcess = React.lazy(() => import('./Components/Pages/SaleProcess/SaleProcess'));
const CallCampaign = React.lazy(() => import('./Components/Pages/CallCampaign/CallCampaign'));
const SearchComponent = React.lazy(() => import('./Components/Pages/Search/Search'));
const NotFound = React.lazy(() => import('./Components/Pages/NotFound/NotFound'));
const LeadFollowUp = React.lazy(() => import('./Components/Pages/FollowUp/LeadFollowUp'));
const ScheduleCallback = React.lazy(() => import('./Components/Pages/ScheduleCallback/ScheduleCallback'));
const Sales360Dashboard = React.lazy(() => import('./Components/Pages/Sales360/Sales360'));
const CustomerFollowUp = React.lazy(() => import('./Components/Pages/CustomerFollowUp/CustomerFollowUp'));

let globalInterval = null;
let tokenCookie = commonHelper.getCookie('token');

/**
 * App Component
 *
 * Description
 * The Component is root component of the application extends React.Component
 * Hosts the following subcomponents
 * @Provider connects the application with the Store
 * @IdleTimeModal popup shown when user is inactive for @getMaxIdleSeconds seconds
 * @Messages shows the global message to users
 * @Search the search component for the application
 * @ContainerLoader the global loader for the application
 * @Router the routing setup for the application
 *
 */


class App extends Component {
  state = {
    showLogoutPopup: false,
    showSidebar: false,
    idleLogoutTimer: false,
    loggingOut: false,
    showSearchBar: false,
    showLogoutModal: false
  }

  componentWillUnmount = () => {
    clearInterval(globalInterval);
  }

  componentDidMount() {
    let secondsPassed = 0;

    globalInterval = setInterval( () => {
      let state = store.getState();
      if(state.global.user) {
        let maxIdleSeconds = commonHelper.getMaxIdleSeconds(state.global.user);
        let maxLogoutIdleSeconds = commonHelper.getMaxLogoutIdleSeconds(state.global.user);

        store.dispatch({ type: USER_INCREASE_IDLE_TIME, payload: null });

        if(state.global.idle_time > maxIdleSeconds) {
            let totalSeconds = maxLogoutIdleSeconds - maxIdleSeconds;
            let totalTimeLeft = totalSeconds - (config.logoutIdleSeconds - state.global.idle_time);
            let percentageTimeCompleted =  commonHelper.getPercentage(totalSeconds, totalTimeLeft);

            this.setState({
              loggingOut: false,
              showLogoutPopup: true,
              idleLogoutTimer: config.logoutIdleSeconds - state.global.idle_time,
              percentageTimeCompleted: percentageTimeCompleted
            });
        } else if(this.state.showLogoutPopup) {
            this.setState({
              showLogoutPopup: false,
              idleLogoutTimer: false
            });
        }

        if(state.global.idle_time > maxLogoutIdleSeconds) {
            this.setState({
              loggingOut: true,
              idleLogoutTimer: false
            });
            this.logoutUser('auto');
        }

        secondsPassed++;
        if(secondsPassed > config.activityUpdateInterval && state.global.idle_time < config.activityUpdateInterval) {
          secondsPassed = 0;
          this.updateUserActivity();
        }
      }
    }, 1000);

    this.updateUserActivity();
  }

  openLogoutConfirmBox = () => {
     this.setState({showLogoutModal: true})
  }

  cancelLogout = () => {
     this.setState({showLogoutModal: false})
  }
  updateUserActivity = () => {
    if(store.getState().global.user) {
      axios.put(
        config.apiUrl + `users/${store.getState().global.user.userid}/activity`, {
              "user_session_id": store.getState().global.user.user_session_id,
              "last_login_id": store.getState().global.user.last_login_id,
              "login_time": store.getState().global.user.login_time
        }
      ).then((response) => {
          store.dispatch({ type: USER_LAST_LOGIN_TIME_UPDATED, payload: response.data.data.login_time });
      })
      .catch((error) => {
        if(error?.response?.status != 401) {
            store.dispatch({
                type: GLOBAL_MESSAGE_TOGGLE, payload: {
                  'message': 'You have been logged out. Since you session has expired!',
                  'type': 'danger'
                }
            });
        }
        this.clearUserSession();
      });
    }
  }

  clearUserSession = () => {
      store.dispatch({
        type: USER_LOGGED_OUT,
        payload: null
      });
      localStorage.clear();
      customHistory.push('/');
      this.setState({ showLogoutPopup: false, showSearchBar: false, showLogoutModal: false });

  }

  logoutUser = (reason = 'manual') => {
      if(reason == 'manual') {
          this.setState({ loggingOutManual: true });
      }
      let state = store.getState();
      axios.delete(config.apiUrl+'logout', {
        params: {
          last_login_id: state.global.user.last_login_id,
          logout_reason: reason
        }
      })
      .then((response) => {
          store.dispatch({ type: GLOBAL_MESSAGE_TOGGLE });
          store.dispatch({ type: USER_CLICKED_APP, payload: null });
          this.clearUserSession();
          if(reason == 'manual') {
              this.setState({ loggingOutManual: false });
          }
      })
      .catch((response) => {
          store.dispatch({ type: USER_CLICKED_APP, payload: null });
          this.clearUserSession();
          if(reason == 'manual') {
              this.setState({ loggingOutManual: false });
          }
      });

  }

  appClicked = () => {
    if(!this.state.loggingOut)
      store.dispatch({ type: USER_CLICKED_APP, payload: null });
  }

  touchStarted = () => {
    if(!this.state.loggingOut)
      store.dispatch({ type: USER_CLICKED_APP, payload: null });
  }

  closeMesssage = () => {
      store.dispatch({ type: GLOBAL_MESSAGE_TOGGLE, payload: null });
  }

  toggleSidebar = () => {
      this.setState((prevState, prevProps) => {
          return {
              showSidebar: !prevState.showSidebar
          };
      });
  }

  goToLastViewedDot = () => {
     if(localStorage.getItem('last_viewed_page')=="sale_process"){
        customHistory.push("/sale-process/"+localStorage.getItem('last_viewed_dotnumber'));
     }
     else{
        localStorage.setItem('getDataOnInit', true);
        customHistory.push("/call-campaign");
     }
  }

  toggleSearchBar = (newState) => {
    if(typeof newState !== 'boolean') {
      this.setState({
        ...this.state,
        showSearchBar: !this.state.showSearchBar
      });
    } else {
      this.setState({
        ...this.state,
        showSearchBar: newState
      });
    }

  }

  render() {
    return (
      <div onClick={this.appClicked} onTouchStart={this.touchStarted} onKeyDown={this.appClicked} onDoubleClick={this.appClicked} onDrag={this.appClicked} onMouseEnter={this.appClicked}>
        <Provider store={store}> {/* Provider connects redux store to our current application*/}
          <IdleTimeModal
            percentageTimeCompleted={this.state.percentageTimeCompleted}
            loggingOut={this.state.loggingOut}
            showLogoutPopup={this.state.showLogoutPopup}
            idleLogoutTimer={this.state.idleLogoutTimer}
            appClicked={this.appClicked} />

         <LogoutConfirmBox
             showLogoutPopup={this.state.showLogoutModal}
             cancelLogout= {this.cancelLogout}
             logoutUser= {this.logoutUser}/>

          <Messages closeMesssage={this.closeMesssage} message={this.props.message}/>
          <Search showSearchBar={this.state.showSearchBar} customHistory={customHistory}/>
          <ContainerLoader />
          {this.state.loggingOutManual ? <Spinner message={'Logging out...'} /> : <React.Suspense fallback={<Spinner />}> {/* Suspend is used with React.lazy for fallback UI when a component is being loaded*/}
            <Router history={customHistory}>
              <Header showSearchBar={this.state.showSearchBar} toggleSearchBar={this.toggleSearchBar} logoutUser={this.logoutUser} showSidebar={this.state.showSidebar} toggleSidebar={this.toggleSidebar} goToLastViewedDot={this.goToLastViewedDot}
                 openLogoutConfirmBox = {this.openLogoutConfirmBox} cancelLogout = {this.cancelLogout}
                 />
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/"><Login /></Route>
                  <Route path="/auto-session-login/:sessionId" exact ><Login /></Route>
                  <PrivateRoute component={Dashboard} path="/dashboard" exact />
                  <PrivateRoute component={SaleProcess} path="/sale-process/:dotnumber" exact />
                  <PrivateRoute component={CallCampaign} path="/call-campaign" exact />
                  <PrivateRoute component={SearchComponent} path="/search" exact />
                  <PrivateRoute component={Sales360Dashboard} path="/sales-360-dashboard" exact />
                  <PrivateRoute  component={LeadFollowUp} path="/lead-followup" exact />
                  <PrivateRoute  component={ScheduleCallback} path="/schedule-callback" exact />
                  <PrivateRoute exact component={CustomerFollowUp} path="/customer-followup" />
                  <Route exact component={NotFound} path="/404"></Route>
                </Switch>
              </ErrorBoundary>
            </Router>
          </React.Suspense>}
        </Provider>
      </div>
    );
  }
}

export default App;
