import { LOADING_STATE_CHANGED, USER_LOGGED_IN, USER_LOGGED_OUT, GLOBAL_MESSAGE_TOGGLE, USER_CLICKED_APP, USER_INCREASE_IDLE_TIME, USER_LAST_LOGIN_TIME_UPDATED } from '../Actions/ActionTypes';
const defaultState = {
	'loading': false,
	'user': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	'token': localStorage.getItem('token') ? localStorage.getItem('token') : null,
	'idle_time': 0
}

/*
*	Reducer for changing application state globally
*/
export default (state = defaultState, action) => {
	switch(action.type) {
		case LOADING_STATE_CHANGED:
			return { ...state, loading: action.payload };
		case USER_LOGGED_IN:
			localStorage.setItem('user', JSON.stringify(action.payload.user));
			localStorage.setItem('token', JSON.stringify(action.payload.token));
			return { ...state, user: action.payload.user, token: action.payload.token };
		case USER_LOGGED_OUT:
			localStorage.clear();
			return { ...state, user: null, token: null };
		case GLOBAL_MESSAGE_TOGGLE:
			return { ...state, message: action.payload };
		case USER_CLICKED_APP:
			return { ...state, idle_time: 0 };
		case USER_INCREASE_IDLE_TIME:
			return { ...state, idle_time: (state.idle_time + 1) };
		case USER_LAST_LOGIN_TIME_UPDATED:
			return { ...state, user: {...state.user, login_time: action.payload } };
		default:
			return state;
	}
}