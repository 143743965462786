import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';

export default (props) => {
  return (
   <React.Fragment>
		<Modal
			  show={props.showLogoutPopup}			  
			  className="close-btn-modal"
           onHide={props.cancelLogout}
			>
			  <Modal.Header closeButton className="border-0 pb-0">
			  </Modal.Header>
			  <Modal.Body>
					<p className="text-center mb-2 head-lg black">Are you sure you want to logout?</p>

			  </Modal.Body>
			  <Modal.Footer className="border-0 mt-2">
						<Col>
							<Button bsPrefix="theme-btn alter light w-100" onClick={props.cancelLogout}>Cancel</Button>
						</Col>
						<Col>
					<Button bsPrefix="theme-btn alter w-100" onClick={props.logoutUser}>Confirm</Button>
						</Col>
					</Modal.Footer>
		 </Modal>
		 </React.Fragment>

	)
}
