import { LOADING_STATE_CHANGED, USER_LOGGED_IN, USER_LOGGED_OUT, GLOBAL_MESSAGE_TOGGLE } from './ActionTypes';

/*
* Use to change global loading state
*/
export const loadingStateChange = (payload) => {
	return {
		type: LOADING_STATE_CHANGED,
		payload: payload
	}
};

/*
* User logged in action
*/
export const userLoggedIn = (payload) => {
	return {
		type: USER_LOGGED_IN,
		payload: payload
	}
}

/*
* User logged out action
*/
export const userLoggedOut = (payload) => {
	return {
		type: USER_LOGGED_OUT,
		payload: payload
	}
}

/*
* Show error/success message
*/
export const toggleMessage = (payload) => {
	return {
		type: GLOBAL_MESSAGE_TOGGLE,
		payload: payload
	}
}