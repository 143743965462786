
import React, {useState} from 'react';
const ScrollArrow = () =>{
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 100){
      setShowScroll(false)
    }
  };
  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  window.addEventListener('scroll', checkScrollTop);
  return (
          <span title="Back to Top" className="back-to-top cursor-pointer" onClick={scrollTop} style={{visibility: showScroll ? 'visible' : 'hidden', opacity: showScroll ? '1' : '0'}}>
  					<i className="fa fa-chevron-up"></i>
  				</span>
    );
  }
  export default ScrollArrow;
