import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, Nav, NavDropdown, Row, Col, Form, Dropdown, Modal, Button } from 'react-bootstrap';
import './Header.scss';
import axios from 'axios';
import config from '../../config';
import { ReactComponent as ReactLogo } from '../../logo.svg';
import { userLoggedOut } from '../../Redux/Actions/GlobalActions';
import Search from '../UI/Search';
import ScrollTop from '../UI/ScrollTop';
import { useLocation } from 'react-router-dom';

const UserSection = (props) => {
	let logoutButton = '';
	const location = useLocation();

	if(props.user) {
		logoutButton = (
			<React.Fragment>
				<Link to='/dashboard' className="text-white header-title mr-sm-3 mr-2 lh-0" title="Home">
					<i className="fa fa-home"></i>
				</Link>
				{props.currentUrl !== '/search' ? <a onClick={props.goToSearch} className="head-link cursor-pointer" title="Search">
					<i className="search-icon"></i>
					<span>Search</span>
				</a> : null}
				<span className="head-link user-name">
					<i className="profile-icon"></i>
					<span>{props.user.firstname} {props.user.lastname}</span>
				</span>
				<span onClick={() => props.openLogoutConfirmBox()} className="head-link cursor-pointer" title="Logout">
					<i className="logout-icon"></i><span>Logout</span>
				</span>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			{logoutButton}
		</React.Fragment>
	)
}



class Header extends React.Component {
	state = {
		showSearch: false,
		title: '',
		currentUrl: '',
		showLogoutModal: false
	}

	changeCurrentUrl = (url) => {
		if(url == '/search') {
			this.props.toggleSearchBar(true);
		} else {
			this.props.toggleSearchBar(false);
		}
		this.setState({ currentUrl: url }, () => {
			console.log("updated state", this.state);
		});
	}

	removeNoHeaderClass = () => {
		if(document.body.classList.contains('no-header'))
			document.body.classList.remove('no-header');
	}

	addNoHeaderClass = () => {
		if(!document.body.classList.contains('no-header'))
			document.body.classList.add('no-header');
	}

	componentDidMount() {
		this.setTitle(this.props.history.location.pathname);
		this.changeCurrentUrl(this.props.history.location.pathname);
		if(this.props.user) {
			this.removeNoHeaderClass();
		} else {
			this.addNoHeaderClass();
		}
		this.props.history.listen((currentLocation, action) => {
			this.setTitle(currentLocation.pathname);
			this.changeCurrentUrl(currentLocation.pathname);
		});
	}

	setTitle = (pathName) => {
		let title = '';
		switch(pathName) {
	        case '/':
	          title='Login';
	          break;
	        case '/dashboard':
	            title='Dashboard';
	          break;
	        case '/call-campaign':
	        	title='Call Campaign'
	          break;
	        case '/search':
	        	title='Search'
	          break;
	        case '/lead-followup':
	        	title='Lead Followup'
	          break;
	        case '/schedule-callback':
	        	title='Schedule Callback'
	          break;
	        case '/sales-360-dashboard':
	        	title='Sales 360 Dashboard'
	          break;
	        case '/customer-followup':
	        	title='Customer FollowUp'
	          break;
	        default:
	        	if(pathName.includes('/sale-process/')) {
	        		title='Lead Details';
	        	} else {
	        		title = 'Dot Compliance Group';
	        	}
	    }
	    document.title = 'DCG - '+title;
	    this.setState({ title: title })
	}

	componentDidUpdate(prevProps) {

		if(prevProps.user != this.props.user) {
			if(this.props.user) {
				document.body.classList.remove('no-header');
			} else {
				document.body.classList.add('no-header');
			}
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
	    if(nextState.showSearch == this.state.showSearch && nextProps.showSidebar == this.props.showSidebar && nextProps.user == this.props.user && nextState.title == this.state.title && nextState.currentUrl == this.state.currentUrl) {
	    	return false;
	    }
	    return true;
	}

	goToDashboard = () => {
		this.props.history.push('/dashboard');
	}

	goToSearch = () => {
		this.props.history.push('/search');
	}




	render() {
		if(!this.props.user)
			return (<React.Fragment></React.Fragment>)
		return (
			<div>
				<header className="fixed-top site-header">
					<div className="inner-container">
						<nav className="navbar navbar-expand-xl">
							{this.state.currentUrl != '/dashboard' && <span onClick={this.goToDashboard} className="back-page">
								<i className="back-icon"></i>
							</span>}
							<Navbar.Brand title="DOT" className="p-0 order-md-1">
								<Link to='/dashboard' title="DOT Compliance Group"><img src={require('../../assets/images/header-logo.png')} height="70" width="133" alt="DOT Compliance Group Logo" className="img-fluid" /></Link>
							</Navbar.Brand>
							<button onClick={this.props.toggleSidebar} type="button" className="navbar-toggler">
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<div className={this.props.showSidebar ? 'show header-navbar ml-auto order-md-2' : 'header-navbar ml-auto order-md-2'}>
								<div className="side-navbar">
									<ul className="navbar-nav my-nav-links">
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/dashboard' ? 'active' : ''}>
											<Link to='/dashboard' className="head-link" title="Home">Home</Link>
										</li>
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/call-campaign' ? 'active' : ''}>
											<Link to='/call-campaign' className="head-link" title="Call Campaign">Call Campaign</Link>
										</li>
										{localStorage.getItem('last_viewed_page') &&
										<li onClick={this.props.toggleSidebar} >
											<a onClick={this.props.goToLastViewedDot} className="head-link cursor-pointer" title="Last Viewed DOT#">Last Viewed DOT#</a>
										</li>}
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/search' ? 'd-xl-none active' : 'd-xl-none'}>
											<Link to='/search' className="head-link" title="Sales Accountability">Sales Accountability</Link>
										</li>
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/sales-360-dashboard' ? 'd-xl-none active' : 'd-xl-none'}>
											<Link to='/sales-360-dashboard' className="head-link" title="360 View">360 View</Link>
										</li>
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/lead-followup' ? 'd-xl-none active' : 'd-xl-none'}>
											<Link to='/lead-followup'className="head-link" title="Lead Follow Up">Lead Follow Up</Link>
										</li>
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/schedule-callback' ? 'd-xl-none active' : 'd-xl-none'}>
											<Link to='/schedule-callback'className="head-link" title="Scheduled Callback">Scheduled Callback</Link>
										</li>
										<li onClick={this.props.toggleSidebar} className={this.state.currentUrl === '/customer-followup' ? 'd-xl-none active' : 'd-xl-none'}>
											<Link to='/customer-followup'className="head-link" title="Customer Follow Up">Customer Follow Up</Link>
										</li>
										<li className="d-none">
										<Dropdown>
											<Dropdown.Toggle tag="button" type="button"  className="head-link">
												More
											</Dropdown.Toggle>

											<Dropdown.Menu alignRight className="page-dropdown">
												<Form.Group controlId="" className="mb-0">
													<div className="search-input-outer header-search">
														<Form.Control
															type="email"
															className="theme-input"
															placeholder="Search Page"
															id=''
															name=''
														/>
													</div>
												</Form.Group>
											</Dropdown.Menu>
										</Dropdown>
										</li>
									</ul>
									<a onClick={this.props.toggleSidebar} title="Close" className="close-icon d-xl-none cursor-pointer"></a>
								</div>
								<span className="sidebar-overlay d-xl-none"></span>
							</div>
							<div className="mobile-nav order-md-3">
								<h1 className="head-xl text-white header-title">{this.state.title}</h1>
								<Nav className="ml-auto user-links">
									<UserSection currentUrl={this.state.currentUrl} openLogoutConfirmBox={this.props.openLogoutConfirmBox} cancelLogout={this.props.cancelLogout} goToSearch={this.goToSearch} toggleSearchBar={this.props.toggleSearchBar} toggleSearch={this.toggleSearch} user={this.props.user} logoutUser={this.props.logoutUser} />
								</Nav>
								</div>
						</nav>
					</div>
				</header>
				<ScrollTop />
				</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.global.user
});

export default withRouter(connect(mapStateToProps, {userLoggedOut})(Header));
