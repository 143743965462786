import config from '../config';
import moment from 'moment-timezone';
const parser = new DOMParser();

const funcs = {
    isExclusiveAdmin: (user) => {
        let exclusive = false;
        let userGroups = user.usergroup[0].split(",");
        if (userGroups.indexOf(String(config.exclusiveGroupId)) != -1) {
            exclusive = true;
        }
        return exclusive;
    },
    isManagerOrAdmin: (user) => {
        let exclusiveAdmin = false;
        if (config.managementIds.indexOf(user.user_id) != -1) {
            exclusiveAdmin = true;
        }
        return exclusiveAdmin;
    },
    isManager: (user) => {
        let manager = false;
        let userGroups = user.usergroup[0].split(",");
        if (userGroups.indexOf(config.managerGroupId) != -1) {
            manager = true;
        }
        return manager;
    },
    getPercentage: (total, fraction, upto = 0) => {
        return ((fraction / total) * 100).toFixed(upto);
    },
    getMaxIdleSeconds: (user) => {
        if (funcs.isManagerOrAdmin(user) || funcs.isManager(user)) {
            return config.maxIdleSecondsExclusiveAdmins;
        } else {
            return config.maxIdleSeconds;
        }
    },
    getMaxLogoutIdleSeconds: (user) => {
        if (funcs.isManagerOrAdmin(user) || funcs.isManager(user)) {
            return config.logoutIdleSecondsExclusiveAdmins;
        } else {
            return config.logoutIdleSeconds;
        }
    },
    formatDateTime: (dateTimeString, time = false) => {
        if (Number.isInteger(dateTimeString)) {
            dateTimeString = dateTimeString * 1000;
        }
        if (time) {
            return moment.tz(dateTimeString, "America/Chicago").format('LLLL');
        } else {
            return moment.tz(dateTimeString, "America/Chicago").format('ll');
    }
    },
    formatTime: (dateTimeString) => {
        if (Number.isInteger(dateTimeString)) {
            dateTimeString = dateTimeString * 1000;
        }
        return moment.tz(dateTimeString, "America/Chicago").format('LT');
    },
    getEpochTime: (dateTimeString) => {
        if (Number.isInteger(dateTimeString)) {
            return dateTimeString;
        }
        return moment.tz(dateTimeString, "America/Chicago").format('X');
    },
    getCardType: (number) => {
        const numberFormated = number.replace(/\D/g, '')
        const patterns = {
            VISA: /^4[0-9]{12}([0-9]{3})?$/,
            MASTER: /^5[1-5][0-9]{14}$/,
            AMEX: /^3[47][0-9]{13}$/,
            //ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
            //AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
            //JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
            //DINERS: /^3(0[0-5]|[68][0-9])[0-9]{11}$/,
            DISCOVERY: /^6011[0-9]{12}$/,
            //HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
            //ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
            //MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
            //DANKORT: /^(5019)\d+$/,
            //INTERPAYMENT: /^(636)\d+$/,
            //UNIONPAY: /^(62|88)\d+$/,
        }
        const names = {
            VISA: "Visa",
            MASTER: "MasterCard",
            DISCOVERY: "Discover",
            AMEX: "American Express"
        }
        for (var key in patterns) {
            if (patterns[key].test(numberFormated)) {
                return names[key] ? names[key] : key;
            }
        }
        return false;
    },
    setCookie: (name, value, expirydays) => {
        var date = new Date();
        date.setTime(date.getTime() + (expirydays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + "; " + expires + ";path=/";
    },
    getCookie: (name) => {
        var name = name + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    deleteCookie: (name) => {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    capitalizeFirstLetter: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    makeCall: (phone_number) => {
        let operatingSystem = funcs.getMobileOperatingSystem();
        if(operatingSystem == 'iOS') {
            window.location = 'glip://'+phone_number;
            setTimeout(function() {
                if(document.hasFocus())
                    window.location = 'https://apps.apple.com/us/app/ringcentral-glip-and-office/id715886894';
            }, 2000);
        } else if(operatingSystem == 'Android') {
            window.location = 'glip://'+phone_number;
            setTimeout(function() {
                if(document.hasFocus())
                    window.location = 'https://play.google.com/store/apps/details?id=com.glip.mobile';
            }, 2000);
        } else {
            window.location = 'tel://' + phone_number;
        }
    },
    isMobileDevice: () => {
        if (funcs.getMobileOperatingSystem() == 'iOS' || funcs.getMobileOperatingSystem() == 'Android') {
            return true;
        } else {
            return false;
        }
    },
    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i))
        {
            return 'iOS';

        } else if (userAgent.match(/Android/i))
        {

            return 'Android';
        } else
        {
            return 'unknown';
        }
    },
    parseHtmlToString(str) {
        if (typeof str == 'undefined') {
            str = '';
        }
        return parser.parseFromString(str, 'text/html').body.textContent;
    },
    checkIntegerString(str) {
        if (typeof str == 'undefined') {
            str = '';
        }
        return !isNaN(parseFloat(str)) && isFinite(str);
    }
}

export default funcs;
