import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './circle.scss';
const parseTime = (timeSeconds) => {
    let minutes = (Math.floor(timeSeconds / 60)) > 9 ? (Math.floor(timeSeconds / 60)) : '0'+(Math.floor(timeSeconds / 60));
    let seconds = (timeSeconds - (minutes * 60)) > 9 ? (timeSeconds - (minutes * 60)) : '0'+(timeSeconds - (minutes * 60));
    return minutes + ':' + seconds;
}

export default (props) => {
  return (
      <Modal
        show={props.showLogoutPopup}        
        className="time-modal no-close-btn-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Number.isInteger(props.idleLogoutTimer) && <section>
            <div>
              <div className={props.percentageTimeCompleted ? "c100 p"+props.percentageTimeCompleted+" big green timer-box" : "c100 p1 big green timer-box"}>
                  <span>{parseTime(props.idleLogoutTimer)}</span>
                  <div className="slice">
                      <div className="bar"></div>
                      <div className="fill"></div>
                  </div>
              </div>
            </div>
            <p className="text-center mb-2">Your session is about to expire.<br/>
            If you would like to keep your session alive, press "Stay Logged in" to continue.</p>
          </section>}
          { props.loggingOut && <section>
              <p className="text-center mb-2">Logging out...</p>
            </section>
          }
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button bsPrefix="theme-btn alter" onClick={props.appClicked}>Stay Logged in</Button>
        </Modal.Footer>
      </Modal>
  );
}
