import React from 'react';

export default (props) => {
	return (
		<div className="loader-outer">
			 <div className="loader">
			 <div class="lds-ring">
				 <span className="logo">
					 <img src={require('../../assets/images/dot-logo.png')} className="img-fluid" height="92" width="80" alt="DOT Logo" />
				 </span>
				 <div></div><div></div><div></div><div></div>
				 </div>
			 </div>
		 </div>
);
}
