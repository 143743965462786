import React from 'react';
import {connect} from 'react-redux';

class ContainerLoader extends React.Component {
	render() {
		return (
			<React.Fragment>
			{this.props.loading ? <div className="loader-outer">			
				 <div className="loader">
				 <div class="lds-ring">
					 <span className="logo">
						 <img src={require('../../assets/images/dot-logo.png')} className="img-fluid" height="92" width="80" alt="DOT Logo" />
					 </span>
					 <div></div><div></div><div></div><div></div>
					 </div>
				 </div>
		    </div> : null}
		    </React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.global.loading
});

export default connect(mapStateToProps)(ContainerLoader);
