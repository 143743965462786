export default {	
	apiUrl: 'https://dcg-api-sales-qa.zapbuild.in/v1/',
	maxIdleSeconds: 540,
	maxIdleSecondsExclusiveAdmins: 3600,
	activityUpdateInterval: 120,
	logoutIdleSeconds: 598,
	logoutIdleSecondsExclusiveAdmins: 3718,
	mobileScreenMaxWidth: 768,
	managementIds: [99254, 6, 6375, 113923, 80857, 89250],
	managerGroupId: 19,
	exclusiveGroupId: 34,
	defaultCountry: 'US',
	ucrUrl: 'https://www.ucr.gov/',
	ringCentral: 'https://app.ringcentral.com/',
	successMessageClass: 'success',
	dangerMessageClass: 'danger',
	warningMessageClass: 'warning',
	saferBaseUrl: 'https://safer.fmcsa.dot.gov/'
}
