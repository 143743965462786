import React from 'react';
import { Collapse } from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Row, Col, Form, Dropdown } from 'react-bootstrap';
import commonHelper from '../../helpers/common';
import config from '../../config';
import RcPhoneCall from './RcPhoneCall';

export default (props) => {
   const processSale = (dotNumber) => {
      props.customHistory.push('/sale-process/'+dotNumber);
   }

   return (
   <div className="theme-card">
      <div className="theme-card-head d-flex justify-content-between align-items-center">
            <h2 className="head-md cursor-pointer"><a onClick={() => processSale(props.result.dotnumber)} className="text-white" title="View Details">DOT# {props.result.dotnumber}</a></h2>
            <div><a onClick={() => processSale(props.result.dotnumber)} class="cursor-pointer theme-btn white sm text-center" title="Lead Details"><span>Lead Details</span> <i className="fa fa-angle-right ml-2"></i></a></div>
      </div>

      <div className="theme-card-body">
         {/* {props.result.is_locked ? <div className="mb-2 alert-card">
            <p className="text text-danger head-md"><i class="fa fa-warning danger mr-2"></i>The DOT# is locked with {props.result.user_first_name} {props.result.user_last_name}.</p>
         </div> : null } */}
         <Row>
            <Col sm={6} lg={3}>
               <div className="detail-cell">
                  <div className="label">Company Name</div>
                  <div className="data">{props.result.company_name ? props.result.company_name : ''}</div>
               </div>
            </Col>

            <Col sm={6} lg={3}>
               <div className="detail-cell">
                  <div className="label">Lead Name</div>
                  <div className="data">{props.result.customer_name ? props.result.customer_name : ''}</div>
               </div>
            </Col>

            <Col sm={6} lg={3}>
               <div className="detail-cell number-cell">
                  <div className="label">Phone Number(s)</div>
                  {props.result.tel_num && <RcPhoneCall tel_num={props.result.tel_num} />}
                  {props.result.cell_num && <RcPhoneCall tel_num={props.result.cell_num} />}
               </div>
            </Col>

            <Col sm={6} lg={3}>
               <div className="detail-cell">
                  <div className="label">Email Address</div>
                  <div className="data">{props.result.email ? props.result.email : '-'}</div>
               </div>
            </Col>

         </Row>
      </div>
   </div>)
}
