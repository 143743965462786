import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

class Messages extends React.Component {
	render() {
		let messages = '';
		if(this.props.message) {
			messages = <div className="">
				<Alert className={this.props.message ? 'show' : ''} transition="fade" onClose={this.props.closeMesssage} variant={this.props.message.type} dismissible>
			        <p>
			          {this.props.message.message}
			        </p>
			      </Alert>
			</div>;
		}
		return (
			<React.Fragment>
		      	{messages}
		    </React.Fragment>
	    );
	}
}

const mapStateToProps = state => ({
  message: state.global.message
});

export default connect(mapStateToProps)(Messages);
